import React, { useEffect, useState } from "react";
import {
  getIsAuthenticatedSelector,
  checkAuthState,
  useAuthenticationDispatch,
  Login,
  logout,
  useUserDataPreloader,
  ForgotPassword,
} from "@quest-finance/quest-fe-shared/dist/auth";
import Loading from "@quest-finance/quest-fe-shared/dist/common/components/Loading";
import NotFoundPage from "@quest-finance/quest-fe-shared/dist/common/components/NotFoundPage";
import ScrollToTop from "@quest-finance/quest-fe-shared/dist/common/components/ScrollToTop";
import APIService from "@quest-finance/quest-fe-shared/dist/common/services/APIService";
import { PageRoute } from "@quest-finance/quest-fe-shared/dist/common/types/PageRoute";
import { PERMISSION_IAM } from "@quest-finance/quest-fe-shared/dist/iam/constants/permissions";
import { Notification } from "@quest-finance/quest-fe-shared/dist/notification";
import { AutoPageReload } from "@quest-finance/quest-fe-shared/dist/version-manager";
import { useSelector } from "react-redux";
import {
  Route,
  BrowserRouter as Router,
  Switch,
  Redirect,
} from "react-router-dom";
import { LayoutContext, SidebarShown } from "../contexts/LayoutContext";

const BaseLayout = React.lazy(() => import("./BaseLayout"));

type BaseRouteProps = {
  bannerRefreshShown?: boolean;
  routes: {
    publicRoutes: PageRoute[];
    privateRoutes: PageRoute[];
  };
};
const BaseRoute: React.FunctionComponent<BaseRouteProps> = ({
  bannerRefreshShown,
  routes,
}: BaseRouteProps) => {
  const isAuthenticated = useSelector(getIsAuthenticatedSelector);
  const [isSidebarMinimized, setIsSidebarMinimized] = useState<boolean>(false);
  const [showSidebar, setShowSidebar] = useState<SidebarShown>(undefined);
  const authDispatch = useAuthenticationDispatch();
  const preloader = useUserDataPreloader();

  useEffect(() => {
    authDispatch(checkAuthState());
    APIService.onTokenRefresh = (error) => {
      if (error) {
        window.location.href = "/auth/login";
        authDispatch(logout());
      }

      authDispatch(checkAuthState());
    };
  }, [authDispatch, isAuthenticated]);

  if (preloader.initialising) {
    return <Loading />;
  }

  return (
    <LayoutContext.Provider
      value={{
        isSidebarMinimized,
        setIsSidebarMinimized,
        showSidebar,
        setShowSidebar,
      }}
    >
      <Notification maxVisible={2} />
      <Router>
        <ScrollToTop />
        <AutoPageReload />
        <React.Suspense fallback={<Loading />}>
          <Switch>
            <Route
              path="/auth/login"
              exact={true}
              render={(props) => (
                <Login
                  {...props}
                  redirectPath="/application/applications"
                  source={PERMISSION_IAM["ACCESS.INTRODUCER_PORTAL"]}
                />
              )}
            />
            <Route
              path="/auth/forgot-password"
              exact={true}
              render={(props) => (
                <ForgotPassword
                  {...props}
                  source={PERMISSION_IAM["ACCESS.INTRODUCER_PORTAL"]}
                />
              )}
            />
            {routes.publicRoutes.map((route, index) => (
              <Route
                key={index}
                exact={route.exact}
                path={route.path}
                render={(props) => <route.component {...props} />}
              />
            ))}
            {routes.privateRoutes.map((route, index) => (
              <Route
                key={index}
                exact={route.exact}
                path={route.path}
                render={(props) => (
                  <BaseLayout bannerRefreshShown={bannerRefreshShown}>
                    <route.component {...props} />
                  </BaseLayout>
                )}
              />
            ))}
            <Route
              exact
              path="/"
              render={() => (
                <Redirect
                  to={
                    !isAuthenticated
                      ? "/auth/login"
                      : "/application/applications"
                  }
                />
              )}
            />
            <Route
              render={() => (
                <NotFoundPage
                  redirectLink={
                    !isAuthenticated
                      ? "/auth/login"
                      : "/application/applications"
                  }
                  redirectLinkText={
                    !isAuthenticated ? "Go to login page" : "Go to dashboard"
                  }
                />
              )}
            />
          </Switch>
        </React.Suspense>
      </Router>
    </LayoutContext.Provider>
  );
};

export default BaseRoute;
